'use client';;
import { useEffect, useState } from 'react';
import { TQuery } from './HeroDropdown';
import HomeDropdown from './HomeDropdown';

export type MakeDropdown = { make: string | null; make_slug: string | null };

export function MakeSearch({
  queryObj,
}: {
  queryObj: {
    query: TQuery;
    setQuery: React.Dispatch<React.SetStateAction<TQuery>>;
  };
}) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    query: { type, year, make, typeId, yearId },
  } = queryObj;
  const [makeData, setMakeData] = useState<MakeDropdown[]>([]);
  const isDisabled = !type || !year;
  const prevSelected = Boolean(
    queryObj &&
      queryObj.query.type &&
      queryObj.query.year &&
      queryObj.query.make === ''
  );

  // useEffect(() => {
  //   // Doing this to warm up the DB
  //   const fetchData = async () => {
  //     try {
  //       await getProductDataByPage();
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchDataMake = async () => {
      try {
        setIsLoading(true);

        const params = new URLSearchParams({
          typeId,
          yearId,
        });

        const response = await fetch(
          `/api/hero-dropdown/make?${params.toString()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setMakeData(data);
      } catch (error) {
        console.error('[Make Search]: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (typeId && yearId) {
      fetchDataMake();
    }
  }, [typeId, yearId]);

  return (
    <HomeDropdown
      place={3}
      title={'make'}
      queryObj={queryObj}
      isDisabled={isDisabled}
      value={make}
      prevSelected={prevSelected}
      items={makeData}
      isLoading={isLoading}
    />
  );
}
