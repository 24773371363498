'use client';
import { useEffect, useState } from 'react';
import { TQuery } from './HeroDropdown';
import HomeDropdown from './HomeDropdown';
import { getAllYearByType } from '@/lib/db';

type DateDropdown = { year_id: any; year: any }[] | null;
export function YearSearch({
  queryObj,
}: {
  queryObj: {
    query: TQuery;
    setQuery: React.Dispatch<React.SetStateAction<TQuery>>;
  };
}) {
  const [yearData, setYearData] = useState<DateDropdown[]>([]);
  const { type, year, typeId } = queryObj.query;
  const isDisabled = !type;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchDataYear = async () => {
      try {
        setIsLoading(true);
        const params = new URLSearchParams({ typeId });
        const response = await fetch(
          `/api/hero-dropdown/year?${params.toString()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setYearData(data);
      } catch (error) {
        console.error('[Year Search]: ', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (typeId) {
      fetchDataYear();
    }
  }, [typeId]);

  const prevSelected =
    queryObj && queryObj.query.year === '' && queryObj.query.type !== '';

  return (
    <HomeDropdown
      place={2}
      title={'year'}
      queryObj={queryObj}
      value={year}
      isDisabled={isDisabled}
      prevSelected={prevSelected}
      items={yearData}
      isLoading={isLoading}
    />
  );
}
