'use client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TQuery } from './HeroDropdown';
import HomeDropdown from './HomeDropdown';

type TypeDropdown = {
  id: string | number;
  name: string | null;
};

type TypeSearchProps = {
  queryObj: {
    query: TQuery;
    setQuery: Dispatch<SetStateAction<TQuery>>;
  };
};

export function TypeSearch({ queryObj }: TypeSearchProps) {
  const {
    query: { type },
  } = queryObj;
  const prevSelected = queryObj?.query.type === '';
  const [typeData, setTypeData] = useState<TypeDropdown[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDataType = async () => {
      try {
        setIsLoading(true);

        const response = await fetch(`/api/hero-dropdown/type`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setTypeData(data);
      } catch (error) {
        console.error('[Type Search]: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataType();
  }, []);

  return (
    <HomeDropdown
      place={1}
      title={'type'}
      value={type}
      queryObj={queryObj}
      prevSelected={prevSelected}
      items={typeData}
      isLoading={isLoading}
    />
  );
}
